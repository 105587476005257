import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';
import { ROLE_FULL_FRAGMENT } from '@/graphql/_Fragments/Role/Full';
import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';

export const COMPANY_USER_ROLE_BASE_FRAGMENT = `
    fragment companyUserRoleBaseFragment on CompanyUserRole {
        uid
        state
        moderator
        manageSales
        manageContent
        manageCompanyUserRoles
        handleMeetings
        canPost
        user {
            ...communityUserBaseFragment
        }
        company {
            ...exhibitorBaseFragment
        }
        role {
            ...roleFullFragment
        }
    }
    ${COMMUNITY_USER_BASE_FRAGMENT}
    ${EXHIBITOR_BASE_FRAGMENT}
    ${ROLE_FULL_FRAGMENT}
`;
